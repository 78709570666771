// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-title {
  color: #646464;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.875rem;
  /* 166.667% */
}

.text-subtitle {
  color: #A1A1A1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
}

.text-text {
  color: rgba(30, 30, 30, 0.90);
  /* font-size: 0.9375rem; */
  font-style: normal;
  font-weight: 400;
  line-height: 1.6875rem;
  /* 180% */
}

.tos ol {
  counter-reset: item;
}

.tos li {
  display: inline-block;
  margin: 1rem;
}

.tos li::before {
  content: counters(item, ".") ".        ";
  counter-increment: item;
}

.pp li {
  margin: 1rem;
}

span {
  margin-left: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/text.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,wCAAwC;EACxC,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".text-title {\n  color: #646464;\n  font-size: 1.5rem;\n  font-style: normal;\n  font-weight: 800;\n  line-height: 1.875rem;\n  /* 166.667% */\n}\n\n.text-subtitle {\n  color: #A1A1A1;\n  font-size: 0.875rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 1.875rem;\n}\n\n.text-text {\n  color: rgba(30, 30, 30, 0.90);\n  /* font-size: 0.9375rem; */\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1.6875rem;\n  /* 180% */\n}\n\n.tos ol {\n  counter-reset: item;\n}\n\n.tos li {\n  display: inline-block;\n  margin: 1rem;\n}\n\n.tos li::before {\n  content: counters(item, \".\") \".        \";\n  counter-increment: item;\n}\n\n.pp li {\n  margin: 1rem;\n}\n\nspan {\n  margin-left: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
